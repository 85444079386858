<template>
    <div>
        <Table :columns="columns" :data="resources" size="small" stripe>
            <template slot-scope="{ row }" slot="barcode">
                {{ row.product ? row.product.barcode : "N/A" }}
            </template>
            <template slot-scope="{ row }" slot="product_name_en">
                {{
                    row.product ? row.product.product_name_en : row.product_name
                }}
            </template>

            <template slot-scope="{ row }" slot="repair_status">
                <span
                    class="badge"
                    :class="repairStatusColor(row.repair_status)"
                    >{{
                        row.repair_product_profiles_status
                            ? row.repair_product_profiles_status.repair_status
                            : ""
                    }}</span
                >
            </template>
            <template slot-scope="{ row }" slot="repaired_by">
                {{
                    row.repaired_by_name
                        ? row.repaired_by_name.employee_name_en
                        : ""
                }}
            </template>
        </Table>
    </div>
</template>
<script>
import expandRowDetail from "./table-expand-detail.vue";
export default {
    props: {
        row: Object
    },
    computed: {
        resources() {
            return this.row.repair_product_profiles;
        },
        columns() {
            return [
                {
                    type: "expand",
                    render: (h, params) => {
                        return h(expandRowDetail, {
                            props: {
                                row: params.row
                            }
                        });
                    },
                    align: "center",
                    width: 50
                },
                {
                    title: this.$t("serviceTicket.barcode"),
                    slot: "barcode",
                    minWidth: 10
                },
                {
                    title: this.$t("serviceTicket.productName"),
                    slot: "product_name_en",
                    minWidth: 10
                },
                {
                    title: this.$t("serviceTicket.serial"),
                    key: "serial_no"
                },
                {
                    title: this.$t("serviceTicket.qty"),
                    key: "quantity",
                    minWidth: 10,
                    align: "center"
                },
                {
                    title: this.$t("serviceTicket.repairStatus"),
                    slot: "repair_status",
                    align: "center"
                },
                {
                    title: this.$t("serviceTicket.repairedBy"),
                    slot: "repaired_by",
                    align: "center"
                },
                {
                    title: this.$t("serviceTicket.completionDate"),
                    key: "completion_date",
                    align: "center",
                    minWidth: 180
                },
                {
                    title: this.$t("serviceTicket.remarks"),
                    key: "remarks"
                }
            ];
        }
    },
    methods: {
        repairStatusColor(value) {
            switch (value) {
                case 1:
                    return "bg-secondary";
                case 2:
                    return "bg-warning";
                case 3:
                    return "bg-success";
                case 4:
                    return "bg-green";
                case 5:
                    return "bg-danger";
                case 6:
                    return "bg-info";
                case 7:
                    return "tw-bg-green-600";
                default:
                    return "bg-secondary";
            }
        }
    }
};
</script>
